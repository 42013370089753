<template>
  <div id="ProfileView" class="grey darken-4">
    <v-container v-if="profile">
      <center>
        <v-img src="@/assets/logo.svg" alt="Ni Hotel Lara" max-width="150" class="mt-3 mb-5" />
      </center>
      <v-card class="mt-3">
        <v-list dense>
          <v-list-item two-line v-for="(phone, $index) in profile.phone_numbers" :key="$index" :href="`tel:${phone.number}`">
            <v-list-item-content>
              <v-list-item-subtitle> {{phone.title}}</v-list-item-subtitle>
              <v-list-item-title>{{
                formatNumber(phone.number)
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-2">fas fa-phone</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line :href="`mailto:${profile.mail}`">
            <v-list-item-content>
              <v-list-item-subtitle>Email</v-list-item-subtitle>
              <v-list-item-title>
                {{ profile.mail }}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-2">far fa-envelope</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line :href="profile.map.link" target="_blank">
            <v-list-item-content>
              <v-list-item-subtitle>Location</v-list-item-subtitle>
              <v-list-item-title>{{profile.address.street}}</v-list-item-title>
              <v-list-item-title>{{profile.address.city}} {{profile.address.zip}}, {{profile.address.state}} / {{profile.address.country}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-2">fas fa-directions</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item two-line :href="`//${profile.homepage}`" target="_blank">
            <v-list-item-content>
              <v-list-item-subtitle>Visit Homepage</v-list-item-subtitle>
              <v-list-item-title>{{profile.homepage}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="grey lighten-2">fas fa-globe-europe</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-card-actions class="grey lighten-5">
          <v-btn text :href="profile.whatsapp" target="_blank">
            <v-icon color="green" class="mr-2">fab fa-whatsapp</v-icon> WHATSAPP
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn plain>Follow us!</v-btn>
          <v-btn icon class="px-4" target="_blank" v-for="(social, $index) in profile.social" :key="$index" :href="social.link">
            <v-icon :color="social.color">{{social.icon}}</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
    <!-- <div v-for="(v, k) in profile" :key="k">
      <label>{{ k }}:</label><span>{{ v }}</span>
    </div> -->

    <!-- Save / Share -->
    <v-speed-dial
      v-if="profile"
      float
      bottom
      left
    >
      <template v-slot:activator>
        <v-btn color="primary" @click="downloadVCF" dark class="mb-3 rounded-pill">
          <v-icon small class="mr-3"> fa-user-plus </v-icon> SAVE
        </v-btn>
        <v-btn color="grey darken-2" @click="shareIt" dark class="ml-3 mb-3 rounded-pill">
          <v-icon small class="mr-3"> fa-share-alt </v-icon> SHARE
        </v-btn>

      </template>
    </v-speed-dial>

    <!-- Speed Dial -->
    <v-speed-dial
      v-if="profile"
      float
      bottom
      right
    >
      <template v-slot:activator>
        <v-btn :href="`tel:${profile.speedDial}`" color="green darken-2" dark fab>
          <v-icon> fa-phone </v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      profile: {
        company:"Ni Hotel Lara",
        homepage:"www.nihotellara.com",
        mail:"frontoffice@nihotellara.com",
        phone_numbers:[
          {title:"Reservation", number:"+902423243742"},
          {title:"Contact", number:"+905334734495"}
          ],
        speedDial: "+905334734495",
        address: {
          street:"Çağlayan Mh 2005 Sk No 30",
          city:"Muratpaşa",
          state:"Antalya",
          country: "TR",
          zip:"07230"
        },
        map:{
          lat: "36.8493603",
          lon: "30.7657291",
          embed: ``,
          link:"https://goo.gl/maps/xFqfL2fDYrQBfoEc9"
        },
        whatsapp:"https://wa.me/905334734495",
        social: [
          /* {
            title:"Whatsapp",
            icon:"fab fa-fw fa-whatsapp",
            color:"green",
            link:"https://wa.me/905334734495"
          }, */
          {
            type:"instagram",
            title:"Instagram",
            icon:"fab fa-instagram",
            color:"purple",
            link: "https://www.instagram.com/nihotellara"
          },
          {
            type:"facebook",
            title: "Facebook",
            icon:"fab fa-facebook-square",
            color:"blue",
            link:"https://www.facebook.com/ni-hotel-lara-113291041127752"
          },
          /* {
            title:"Tripadvisor",
            icon:"fa fa-tripadvisor",
            color:"green darken-1",
            link:"https://www.tripadvisor.com.tr/Hotel_Review-g15300585-d23826697-Reviews-Ni_Boutique_Hotel_Lara-Muratpasa_Antalya_Turkish_Mediterranean_Coast.html?m=19905"
          } */
        ]
      },
    };
  },
    methods: {
    downloadVCF($event) {
      if (!this.profile) return;

      const REV = this.$moment.utc().format("YYYYMMDDTHHmm") + "Z";

      const socials = this.profile.social.map((social)=> `X-SOCIALPROFILE;type=${social.type}:${`social.link`}`)

      const lines = [
        `BEGIN:VCARD`,
        `VERSION:3.0`,
        `N:;${this.profile.company};;;`,
        `FN:${this.profile.company}`,
        //`ORG:${this.profile.company};`,
        `item1.URL;type=INTERNET;type=WORK:${this.profile.homepage}`,
        `item1.X-ABLabel:website`,
        `EMAIL;type=INTERNET;type=WORK;type=pref:${this.profile.mail}`,
        //`TEL;type=CELL;type=VOICE;type=pref:${this.profile.phone_numbers[1].number}`,
        `TEL;type=WORK;type=VOICE;type=pref:${this.profile.phone_numbers[0].number}`,
        `TEL;type=WORK;type=VOICE;type=pref:${this.profile.phone_numbers[1].number}`,
        socials,
        //`X-SOCIALPROFILE;type=facebook:https://facebook.com/${this.profile.facebook}`,
        //`X-FACEBOOK:${this.profile.facebook}`,
        //`X-SOCIALPROFILE;type=twitter:https://twitter.com/${this.profile.twitter}`,
        //`X-TWITTER:${this.profile.twitter}`,
        //`X-SOCIALPROFILE;type=linkedin:https://www.linkedin.com/company/${this.profile.linkedin}`,
        //`X-LINKEDIN:${this.profile.linkedin}`,
        `REV:${REV}`,
        `END:VCARD`,
      ];

      const fileName = `${this.profile.company}.vcf`;
      const vcf = new Blob([Buffer.from(lines.join("\n"))], {
        type: "text/vcard; charset=utf-8",
      });

      if (navigator.msSaveBlob) navigator.msSaveBlob(vcf, fileName);
      else {
        var a = document.createElement("a");
        if (a.download !== undefined) {
          var uri = URL.createObjectURL(vcf);
          a.setAttribute("href", uri);
          a.setAttribute("download", fileName);
          a.style.visibility = "hidden";
          document.body.appendChild(a);
          a.click($event);
          document.body.removeChild(a);
        }
      }
      console.log(vcf);
    },
    shareIt($event) {
      if ($event && $event.preventDefault()) $event.preventDefault();

      navigator &&
        navigator.share &&
        navigator.share({
          title: this.profile.name + " (vcf)",
          text: ""
            .concat(this.profile.name, "\n")
            .concat(`${this.profile.company}, ${this.profile.job}`)
            .concat(this.profile.phone_number, "\n")
            .concat(this.profile.mail, "\n"),
          url: window.location.href,
        });
    },
    formatNumber(input) {
      const formatter = /(^\+?9?0?)(\d{3})(\d{3})(\d{4})$/g
     return input.replace(/\D/g,'').replace(formatter, '$1 $2 $3 $4');
    },
  },
}
</script>

<style lang="scss">
:root {
  overflow: hidden;
}
#ProfileView {
  min-height: 100vh;
  overflow: auto;

  .v-speed-dial {
    position: fixed;
  }
}
</style>