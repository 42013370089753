import Vue from 'vue'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDhaepaSxvGV61Duu4db3SayhAeQmtj9rg",
  authDomain: "nihotel.firebaseapp.com",
  projectId: "nihotel",
  storageBucket: "nihotel.appspot.com",
  messagingSenderId: "194077958669",
  appId: "1:194077958669:web:2c8965e2356d8ae01ceb44",
  measurementId: "G-WQFCD98XXY"
};
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
Vue.prototype.$analytics = analytics;

// Initialize moment-js
import moment from 'moment';
Vue.prototype.$moment = moment;


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
